/* body{
  height:0px;
  width: 360px !important;

} */

.chat-bot-ftech{
  bottom: 12px;
  right: 40px;
  /* bottom: 0px;
  right: 0px; */
  position: fixed;
}

.icon-close-chat{
  border-radius: 50%;
  background-color: #F6F6F6;
  padding: 5px;
  cursor: pointer;
  max-width: 30px;
}


@media screen and (max-width: 500px) {
  body{
    height:0px;
    width: 100% !important;
  
  }

  .chat-bot-ftech{
    bottom: 0px;
    right:0px;
    position: fixed;
  }
}