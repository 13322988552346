.markdown h1 {
  font-size: 1.75em;
  font-weight: bold;
}

.bot-messenger-content .markdown{
  line-height: normal !important;
}
.markdown .table {
  width: 100% !important;
}
.markdown blockquote {
  margin-top: 41px;
  margin-bottom: 34px;
  font-size: 16px;
  font-weight: 400;
  border-left: 5px solid #eee;
  font-style: italic;
  padding: 10px 20px;
}
.markdown ul {
  display: block;
  list-style-type: disc;
  margin: 1em 0 1em;
  padding-left: 40px
}
.markdown ol {
  display: block;
  list-style-type: decimal;
  margin: 1em 0;
  padding-left: 40px
}
.markdown h2 {
  font-size: 1.5em;
  font-weight: bold;
}
.markdown h3 {
  font-size: 1.17em;
  font-weight: bold;
}
.markdown h4 {
  font-size: 1.17em;
  font-weight: bold;
}
.markdown a {
  color: rgb(37 99 235);
}
.markdown p {
  display: block;
  margin: 1em 0;
}
