.self-chat{
    height:fit-content;
}

.self-chat-content{
    background-color: #FF884B;
    height:fit-content;
    padding: 8px 12px;
    border: 1.5px solid #F2F2F2;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    max-width: 300px;
    font-family: 'SVN-Poppins', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    word-break: break-word;
}

.self-chat-content p {
	font-family: 'SVN-Poppins', sans-serif !important;
}

.link-url:hover {
	text-decoration: underline;
}

.link-url {
	color: blue;
}

