.windows-chat {
    width: 370px;
    height:calc(100vh - 100px)  !important;
    max-height: 620px;
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
}

.content-chat {
    overflow-y: scroll;
    overflow-x: hidden;
}

.content-chat::-webkit-scrollbar {
    width: 8px;
}

.content-chat::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 5px;
    background: #f1f1f1;
}

.content-chat::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
}

.time-messenger {
    margin: 6px 4px 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #595959
}

.top-bar-chat {
    z-index: 99;
    height: 60px;
    min-height: 60px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.footer-chat {
    /* min-height: 50px; */
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    /* border-radius: 8px 8px 0px 0px; */
}

.windows-chat .input-chat input {
    outline: none
}

.windows-chat .input-chat textarea {
    max-height: 72px;
    outline: none;
    resize: none;
}

.windows-chat .input-chat::placeholder {
    color: #9D9D9D;
    opacity: 1;
}

.popup-rating {
    
    background-color: #424242;
    z-index: 10;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    border-radius: 8px;

}

.popup-rating-content {
    z-index: 20;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: fit-content;
    max-width: 300px;
    margin: auto;
    background-color: #fff;
    border-radius: 4px;
}

.popup-rating-content .send-text {
    color: #0067DD;
    border-top: 1px solid #C1C1C1;
}

.font-F p {
	font-family: 'SVN-Poppins', sans-serif !important;
	font-style: normal;
}

.ChatGPT {
	color: #2dd5fa !important;
	font-size: 10px !important;
	font-weight: 400 !important;
	line-height: 24px !important;
}

.ChatGPT a {
	font-size: 10px !important;
}

@media screen and (max-width: 992px) {
    .windows-chat {
        max-height: 100% !important;
        width: 100vw !important;
        position: fixed;
        bottom:  0 !important;
        right: 0px;
        height: calc(100vh - calc(100vh - 100%)) !important;
        border-radius:0px
    }
  }

  .lds-dual-ring {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: auto;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid #000;
    border-color: #000 transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }