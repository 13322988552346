
.react-stars span{
    margin: 0px 10px;
}

.bot-messenger{
    height:fit-content;
}

.bot-messenger-content{
    white-space: pre-wrap;
    height:fit-content;
    padding: 8px 12px;
    background: #F0F0F0;
    border: 1.5px solid #F2F2F2;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    max-width: 300px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
    word-break: break-word;
}

.bot-messenger-content a{
    text-decoration: underline;
    color: blue
}

.bot-messenger-content:hover{
    background: #DDF3FF;
    border: 1.5px solid #2F80ED;
}

.bot-question-suggest{
    padding: 5px 8px;
    color:#2F80ED;
    border: 1px solid #2F80ED;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    max-width: 240px !important;

}

.bot-messenger-rating{
    height:fit-content;
    padding: 8px 12px;
    background: #FFFFFF;
    border: 1.5px solid #F2F2F2;
    border-radius: 10px;
    max-width: 240px;
    font-family: 'SVN-Poppins', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #595959;
}

.block-messenger-rating{
    min-width: 293px;
    border: 1.5px solid #F2F2F2;
    border-radius: 10px;
}

.rating-comment textarea{
    min-width: 255px;
    background-color: #EFEFEF;
    border-radius: 2px; 
    outline: none;
    padding: 10px 20px;
    resize: none;
    font-size: 12px;
    line-height: 14px;
}


.rating-comment textarea::-webkit-scrollbar {
    display: none;
  }

.rating-comment textarea::placeholder{
    font-size: 12px;
    line-height: 14px;
    color: #939393;
}


.list-image{
    overflow: hidden;
    max-width: 270px;
}