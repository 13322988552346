
.small-logo-chat{
    z-index: 100;
    /* position: fixed;
    bottom: 20px;
    align-items: center;
    justify-content: center; */
    /* background-color: white; */
}


.mesenger-auto{
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px 10px 0px 10px;
    max-width: 240px;
    min-width: 240px;
    background-color: #fff;
    bottom: 35px;
    right: 60px;
    z-index: 99;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

}


.mesenger-auto-text{
    padding: 9px 28px 9px 14px;
    font-family: Roboto;
    font-weight: 400;
    size:18px;
    line-height: 21.09px;
    color: #2F80ED;
}

.live-icon {
    display: inline-block;
    position: relative;
    top: calc(50% - 5px);
    background-color: #2dd5fa;
    width: 60px;
    height: 60px;
    margin-left: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    z-index: 1;
    right: 17px;
  }
.live-icon:before {
    content: "";
    display: block;
    position: absolute;
    background-color: #2dd5fa;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: live 2s ease-in-out infinite;
            animation: live 2s ease-in-out infinite;
    z-index: -1;
    pointer-events: none;
  }
  
  @-webkit-keyframes live {
    0% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(3.5, 3.5);
      background-color: rgba(255, 0, 0, 0);
    }
  }
  
  @keyframes live {
    0% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(3.5, 3.5);
      background-color: rgba(255, 0, 0, 0);
    }
  }

  .tooltiptext {
    align-items: center;
    background-color: #2dd5fa;
    border-radius: 6px;
    color: #fff;
    display: flex;
    height: 47px;
    justify-content: center;
    margin-left: -60px;
    padding: 5px;
    position: absolute;
    right: 113%;
    text-align: center;
    top: 5%;
    transition: opacity .3s;
    width: 135px;
    z-index: 1;
    cursor: pointer;
}

.tooltiptext:before {
    border: 8px solid transparent;
    border-left-color: #2dd5fa;
    bottom: 100%;
    content: "";
    margin-left: -5px;
    position: absolute;
    right: -11%;
    top: 35%;
}

@media screen and (max-width: 500px) {
    .wrap-logo{
        bottom: 55px !important;
        position: fixed !important;
        width: 60px !important;
        right: 10px !important;
    }
    
  }